import Cookies from 'js-cookie';
import {ProductTypes} from './typescript-types/products';

const getItemPrice = (item, currency) => {
  let price = '0.00';
  if ('pricing' in item) {
    price = item.pricing?.[currency]?.current_amount?.toFixed(2)?.toString() ?? '0.00';
  } else if ('prices' in item) {
    price = item.prices?.[currency]?.toFixed(2)?.toString();
  }

  return parseFloat(price);
};

const getPayload = (token, window) => window.JSON.parse(window.atob(token.split('.')[1]));

const getUserType = (user) => {
  if (user?.scopes) {
    if (user.scopes.includes('staff')) {
      return 'staff';
    } else if (user.scopes.includes('provider')) {
      return 'provider';
    } else if (user.scopes.includes('user')) {
      return 'registered';
    }
  }

  return 'anonymous';
};

const serializeProductForEcommerceEvent = (
  item,
  productCurrency,
  artistSlug = null,
  categorySlug = null,
  index = 0,
) => {
  return {
    item_id: item.id.toString(),
    item_name: item.slug,
    coupon: null,
    currency: productCurrency,
    discount: null,
    index: index,
    item_brand: item.artist_slug ?? artistSlug,
    item_category: item.category_slug ?? categorySlug,
    item_category2: item.style_slug ?? null,
    item_category3: item.subject_slug ?? null,
    price: getItemPrice(item, productCurrency),
    quantity: 1,
  };
};

const serializePromotionForEcommerceEvent = (id, name) => {
  return {
    promotion_id: id,
    promotion_name: name,
  };
};

const serializeVoucherForEcommerceEvent = (voucher) => {
  return {
    content_type: 'voucher',
    item_id: voucher.id,
    item_name: voucher.internal_name,
  };
};

const serializeProductForGTMVars = (
  item,
  productCurrency,
  artistSlug = null,
  categorySlug = null,
  index = 0,
) => {
  return {
    item_id: item.id.toString(),
    item_name: item.slug,
    currency: productCurrency,
    item_brand: item.artist_slug ?? artistSlug,
    item_category: item.category_slug ?? categorySlug,
    item_category2: item.style_slug ?? null,
    item_category3: item.subject_slug ?? null,
    price: getItemPrice(item, productCurrency),
  };
};

export const gaEvent = (eventType, ecommerce) => {
  const data = {
    event: eventType,
    ecommerce: ecommerce,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push(data);
};

export const getInitialDataLayer = () => {
  let userId = null;
  const initData = {
    environment: process.env.ENVIRONMENT,
    userType: 'anonymous',
    userCountry: 'GB',
    userCurrency: 'GBP',
  };

  try {
    initData['userCountry'] = Cookies.get('af-shipping') || 'GB';
    initData['userCurrency'] = Cookies.get('af-currency') || 'GBP';
  } catch (e) {
    console.log(e);
  }

  try {
    const token = Cookies.get('sessionid');
    if (token) {
      const user = getPayload(token, window) || {};
      initData['userType'] = getUserType(user);
      userId = user?.sub || null;
      if (userId) {
        initData['userId'] = userId;
        initData['userEmail'] = user?.email || null;
      }
    }
  } catch (e) {
    console.log(e);
  }

  return initData;
};

export const customEvent = (action, payload = {}) => {
  const event = {event: action};
  const data = {...event, ...payload};
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const data = (payload) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(payload);
};

export const historyChangedEvent = () => {
  setTimeout(function () {
    // we need to wait until url change is consolidated so GA can pick it up
    return customEvent('af.history_changed');
  }, 500);
};

export const historyNewPagesChangedEvent = () => {
  setTimeout(function () {
    // we need to wait until url change is consolidated so GA can pick it up
    return customEvent('af.history_changed__world_ui');
  }, 500);
};

export const followArtistEvent = (item) => {
  return customEvent('follow_artist', {
    slug: item.slug,
  });
};

export const newsletterSubscriptionEvent = () => {
  return customEvent('newsletter_subscription');
};

export const pushProductDataForGTMVars = (
  item,
  currency: string | null = null,
  artistSlug,
  categorySlug,
) => {
  const productCurrency = currency ?? item.currency ?? item.original_currency ?? 'GBP';
  data({
    product: serializeProductForGTMVars(item, productCurrency, artistSlug, categorySlug),
  });
};

export const pushGAViewItemEvent = (
  item,
  currency: string | null = null,
  artistSlug,
  categorySlug,
) => {
  const productCurrency = currency ?? item.currency ?? item.original_currency ?? 'GBP';
  gaEvent('view_item', {
    currency: productCurrency,
    value: getItemPrice(item, productCurrency),
    items: [serializeProductForEcommerceEvent(item, artistSlug, categorySlug, productCurrency)],
  });
};

export const pushGAViewItemListEvent = (
  items: ProductTypes[] | [],
  currency: string | null = null,
  startIndex = 0,
) => {
  if (!items || !Array.isArray(items)) return;

  const productCurrency =
    currency ?? items?.[0]?.currency ?? items?.[0]?.original_currency ?? 'GBP';
  let index = startIndex;
  gaEvent('view_item_list', {
    items: items.map((x) =>
      serializeProductForEcommerceEvent(x, productCurrency, null, null, index++),
    ),
  });
};

export const pushGASelectItemEvent = (item, currency: string | null = null) => {
  const productCurrency = currency ?? item.currency ?? item.original_currency ?? 'GBP';
  gaEvent('select_item', {
    items: [serializeProductForEcommerceEvent(item, productCurrency)],
  });
};

export const pushGAAddToCartEvent = (items, currency = null) => {
  const productCurrency = currency ?? items[0].currency ?? items[0].original_currency ?? 'GBP';
  const value = items
    .flatMap((x) => getItemPrice(x, productCurrency))
    .reduce((acc, curr) => (acc += curr), 0);
  gaEvent('add_to_cart', {
    currency: productCurrency,
    value: value,
    items: items.map((item) => serializeProductForEcommerceEvent(item, productCurrency)),
  });
};

export const pushGAAddToWishlistEvent = (item: any, currency: string | null = null) => {
  const productCurrency = currency ?? item.currency ?? item.original_currency ?? 'GBP';
  gaEvent('add_to_wishlist', {
    currency: productCurrency,
    value: getItemPrice(item, productCurrency),
    items: [serializeProductForEcommerceEvent(item, productCurrency)],
  });
};

export const pushGAViewPromotionEvent = (id, name) => {
  gaEvent('view_promotion', serializePromotionForEcommerceEvent(id, name));
};

export const pushGASelectPromotionEvent = (id, name) => {
  gaEvent('select_promotion', serializePromotionForEcommerceEvent(id, name));
};

export const pushGASelectVoucherEvent = (voucher) => {
  gaEvent('select_content', serializeVoucherForEcommerceEvent(voucher));
};

export const pushGAJoinMailingListEvent = (source: 'Homepage') => {
  gaEvent('generate_lead', {lead_source: source});
};

export const registrationEvent = () => {
  gaEvent('sign_up', {});
};

export const loginEvent = () => {
  gaEvent('login', {});
};

export const searchEvent = (searchTerm) => {
  gaEvent('search', {
    search_term: searchTerm,
  });
};

const artistEventKeys = {
  featured: {
    eventName: 'homepage_featured_artist_click',
    positionKey: 'homepage_featured_artist_position',
    artistName: 'homepage_featured_artist_name',
  },
  popular: {
    eventName: 'homepage_popular_artist_click',
    positionKey: 'homepage_popular_artist_position',
    artistName: 'homepage_popular_artist_name',
  },
  discover: {
    eventName: 'homepage_global_artist_click',
    positionKey: 'homepage_global_artist_position',
    artistName: 'homepage_global_artist_name',
  },
};

export const pushGAClickArtistCardEvent = (
  artistSlug: string,
  position: number,
  type: 'featured' | 'popular' | 'discover',
) => {
  const {eventName, positionKey, artistName} = artistEventKeys[type];

  customEvent(eventName, {
    [positionKey]: position,
    [artistName]: artistSlug,
  });
};

export const pushGAPopularSearchesEvent = (linkText: string, linkHref: string) => {
  customEvent('homepage_popular_searches_click', {
    homepage_popular_searches_block: linkText,
    homepage_popular_searches_link: linkHref,
  });
};

export const pushGAShopByCategoryEvent = (linkText: string, linkHref: string) => {
  customEvent('homepage_category_click', {
    homepage_category_block: linkText,
    homepage_category_link: linkHref,
  });
};

export const pushGAShopByPriceEvent = (linkText: string, linkHref: string) => {
  customEvent('homepage_price_category_click', {
    homepage_price_category_name: linkText,
    homepage_price_category_slug: linkHref,
  });
};

export const pushGAClickBottomUSPEvent = (blockName: string) => {
  setTimeout(function () {
    // we need to wait until url change is consolidated so GA can pick it up
    customEvent('homepage_trust_block_click', {
      homepage_trust_block_name: blockName,
    });
  }, 500);
};

export const pushGAClickEditorsPicksEvent = (name: string, slug: string, position?: number) => {
  const eventPayload: Record<string, string | number> = {
    homepage_editors_pick_name: name,
    homepage_editors_pick_slug: slug,
  };
  if (!!position) {
    eventPayload.homepage_editors_pick_position = position;
  }

  customEvent('homepage_editors_pick_click', eventPayload);
};

export const pushGAClickHomePageBanner = (name: string) => {
  const position = 0;
  gaEvent('homepage_banner_click', {
    banner_position: position,
    banner_name: name,
    banner_label: `${position} - ${name}`,
  });
};

export const pushGARecentlyViewedCardEvent = (position: number, slug: string) => {
  customEvent('homepage_recently_viewed_click', {
    homepage_recently_viewed_position: position,
    homepage_recently_viewed_click: slug,
  });
};
